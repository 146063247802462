.icon {
    position: relative;
    top: 10px;
    right: 25px;
    stroke: #5e7f89;
    fill: #5e7f89;
    outline: none;
}

.table tbody td:first-child .icon {
    position: static;
    display: inline-flex;
    margin-top: 10px;
}

.tooltip {
    font-size: 12px;
    line-height: 14px;
    min-width: 200px;
}

.tooltip div:first-child {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
}

.tooltip div {
    margin-bottom: 10px;
}

.tooltip div:not(:first-child) {
    display: flex;
    justify-content: space-between;
}

.icon:hover,
.addressLink:hover {
    cursor: pointer;
}

.icon table {
    position: relative;
    top: 10px;
    right: 25px;
}

.table {
    margin-top: 10px;
}

.table th {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: var(--primary-color);
    vertical-align: bottom;
    letter-spacing: 0.3px;
    padding: 11px 10px;
    white-space: nowrap;
}

.table tbody tr {
    border-top: 1px solid #dddddd;
    height: 75px;
}

.table tbody tr:hover {
    background: var(--primary-bg-color);
    color: #ffffff !important;
    cursor: pointer;
}

.table tbody td {
    padding: 15px 20px 15px 10px;
    color: #13353f;
}

.actions {
    padding-right: 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.date {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    color: #5e7f89;
}

.comment {
    outline: none;
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    padding: 10px;
}

.actionButtons {
    text-align: center;
    margin-top: 20px;
}
