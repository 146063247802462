.addressLink {
    color: var(--primary-color) !important;
    margin-left: 20px;
    border-bottom: var(--primary-color) 1px dotted;
}

.inlineAddressLink {
    color: var(--primary-text-color);
    margin-left: 20px;
}

.inlineAddressLink > span {
    color: var(--primary-color) !important;
}

.addressLink:hover {
    cursor: default;
}
