.container {
    background: #fff;
    width: 100%;
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 14px;
    line-height: 1.4;
}

.title {
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
    margin: 0 0 20px;
}

.backLink {
    color: var(--primary-color);
    padding: 0 0 26px 0;
    margin-bottom: 26px !important;
    border-bottom: solid 1px #ccc;
}

.userName {
    font-size: 27px;
    font-weight: bold;
}

.editIcon {
    margin-left: 10px;
}

.editIcon:hover {
    cursor: pointer;
}

.userEmail {
    margin: 10px 0;
}
.changeNameModal {
    display: flex;
    flex-direction: column;
}

.fullName {
    margin: 10px 0 40px 0;
}

.actions {
    display: flex;
    align-items: flex-end;
}

.actions {
    display: flex;
    align-items: flex-end;
}

.actions button {
    margin: 20px 20px 0 0;
}

.removeButton {
    margin-bottom: 8px;
    text-decoration: underline;
    color: var(--primary-color);
}

.removeButton:hover {
    cursor: pointer;
}

.permissions {
    display: flex;
    flex-direction: column;
}

.permissions input {
    margin-left: 0;
}

.actionModal {
    margin-bottom: 30px;
}

.actionButtons {
    display: flex;
    justify-content: space-between;
}
