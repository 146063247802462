:root {
    --white: white;
    --black: black;
}

#Main {
    position: relative;
    top: -40px;
}

.w-layout-vflex {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.w-layout-blockcontainer {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.w-layout-hflex {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
}

@media screen and (max-width: 991px) {
    .w-layout-blockcontainer {
        max-width: 728px;
    }
}

@media screen and (max-width: 767px) {
    .w-layout-blockcontainer {
        max-width: none;
    }
}

p {
    margin-bottom: 10px;
    text-decoration: none;
}

.container {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-flow: column;
    width: 980px;
    display: flex;
}

.link-block {
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
    background-position: 0 0;
    background-size: auto;
}

.link-block.w--current {
    background-image: url('images/logo.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 0 auto;
    width: 166px;
    height: 30px;
    display: flex;
}

.flex-block {
    background-color: #fff;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
}

.heading {
    margin-top: 0;
    margin-bottom: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 110%;
}

.paragraph {
    flex: 1;
    align-self: stretch;
    max-width: 600px;
    margin-bottom: 0;
    line-height: 110%;
}

.line {
    background-color: #e9eaee;
    height: 1px;
}

.flex-block-2 {
    grid-column-gap: 80px;
    grid-row-gap: 80px;
}

.select_block {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex: 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
}

.heading-2 {
    color: #13353f;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 110%;
}

.heading-2.gray {
    color: #658692;
    font-size: 19px;
    font-weight: 500;
}

.heading-2.link_menu {
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
}

.heading-2.link_menu:hover {
    color: #08c3dc;
}

.container {
    margin: 0 auto;
}

.container,
h1,
h2 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 18px;
}

.container h1,
.container h2 {
    color: #13353f;
}

.flex-block-3 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex: 0 auto;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.paragraph-2 {
    color: #658692;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 110%;
}

.flex-block-4 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    cursor: auto;
    flex: 0 auto;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.content_block {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    display: flex;
}

.flex-block-5 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    border: 1px solid #e9eaee;
    border-radius: 1px;
    justify-content: flex-start;
    align-items: center;
    max-width: 600px;
    padding: 10px 15px;
}

.name {
    color: #13353f;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 120%;
}

.flex-block-6 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.link-block-2 {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    background-color: #13353f05;
    border: 1px solid #e9eaee;
    border-radius: 1px;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-width: 0;
    max-width: 600px;
    padding: 10px 15px;
    text-decoration: none;
    display: flex;
}

.link-block-2:hover {
    color: #08c3dc;
    -webkit-text-stroke-color: #08c3dc;
    border-color: #08c3dc;
}

.link-block-2:active {
    border-color: #00a9bf;
}

.paragraph-3 {
    pointer-events: auto;
    color: #13353f;
    max-width: 600px;
    margin-bottom: 0;
    line-height: 110%;
}

.paragraph-3.orange {
    color: #f79440;
    padding-top: 10px;
    font-weight: 500;
}

.paragraph-3.orange.purple {
    color: #9179c8;
}

.paragraph-3.bold {
    font-weight: 600;
}

.close_bnt {
    cursor: pointer;
    display: inline-flex;
}

.open_bnt {
    cursor: pointer;
}

.flex-block-7 {
    justify-content: space-between;
    align-items: flex-end;
}

.flex-block-8 {
    background-color: #13353f0d;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.div-block {
    background-color: #00000080;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    height: 100vh;
    display: none;
    position: fixed;
    inset: 0% 0% 0% auto;
    overflow: visible;
}

.div-block.menu_block {
    z-index: 99;
}

.content_menu {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    background-color: #fff;
    flex: 0 auto;
    justify-content: flex-start;
    align-self: stretch;
    align-items: flex-start;
    width: 600px;
    padding: 120px 80px;
    position: sticky;
    inset: 0% auto auto 0%;
}

.link-block-3,
.link-block-4,
.link-block-5,
.link-block-6,
.link-block-7,
.link-block-8,
.link-block-9,
.link-block-10,
.link-block-11,
.link-block-12,
.link-block-13,
.link-block-14,
.link-block-15,
.link-block-16 {
    text-decoration: none;
}

.image {
    cursor: pointer;
    padding-top: 60px;
    padding-right: 60px;
    position: absolute;
    inset: 0% 0% auto auto;
}

.image-2 {
    cursor: pointer;
}

.link-block-17 {
    position: fixed;
}

.link-block-17.w--current {
    z-index: 2;
    position: fixed;
    bottom: 60px;
    right: 60px;
}

.flex-block-9 {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
}

@media screen and (max-width: 479px) {
    .container {
        width: 100%;
    }

    .heading {
        width: 100%;
        font-size: 28px;
    }

    .paragraph {
        font-size: 16px;
    }

    .flex-block-2 {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        flex-flow: column;
    }

    .heading-2 {
        font-size: 18px;
        line-height: 110%;
    }

    .paragraph-2 {
        font-size: 18px;
    }

    .paragraph-3 {
        font-size: 16px;
    }

    .flex-block-7 {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        flex-flow: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .div-block.menu_block {
        display: none;
    }

    .content_menu {
        padding: 80px 20px 20px;
    }

    .image {
        padding-top: 20px;
        padding-right: 20px;
    }

    .link-block-17.w--current {
        bottom: 20px;
        right: 20px;
    }
}
