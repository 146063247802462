.container {
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
}

.beginPageLink {
    display: flex;
    width: 100%;
}

.pageLinks {
    display: flex;
    overflow: hidden;
    justify-content: flex-end;
    width: 100%;
}

.link:not(:last-child) {
    margin: 0 30px;
}
