.footnote {
    display: block;
    font-size: 14px;
    color: #ff6a13;
    align-self: flex-start;
    margin-top: 20px;
}

.userComment {
    padding: 3px 5px 3px 5px;
}

.userComment:hover {
    cursor: text;
}

.edit {
    width: 16px;
    height: 16px;
    stroke: var(--primary-color);
    fill: #ffffff;
    margin-left: 10px;
}

.edit:hover {
    cursor: pointer;
}
