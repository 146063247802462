.container {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 38px 38px 76px 38px;
}

.title {
    margin: 0 0 20px;
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 50px;
}

.modalContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    color: #000000;
}

.noticeDocs {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #797979;
    margin: 30px 0;
}

.pdfIcon {
    stroke: #ffffff;
    stroke-width: 1;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.button:first-child {
}

.button:last-child {
    margin-top: 20px;
    border: 2px solid var(--primary-color);
    outline: none;
    background: transparent;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    border-radius: 0;
    transition: all ease-in-out 350ms;
}

.button:last-child:hover {
    color: #fff;
    background: var(--buttons-hover-bg-color);
    border-color: var(--buttons-hover-bg-color);
}
