.container {
    display: flex;
    margin-bottom: 23px;
}

.arrowRight {
    margin-left: 10px;
    margin-right: 10px;
    transform: rotate(-180deg);
}
