.container {
    width: 100%;
}

.title {
    margin: 70px 0 25px 67px;
    font-size: 36px;
    color: var(--h-entry-color);
}

.content {
    display: flex;
    background-color: #fff;
    padding: 50px 50px;
    justify-content: var(--form-justify-content);
}

.noticeBlock {
    background: #fff;
    margin-bottom: 30px;
}

.form {
    margin: 70px;
    width: 300px;
}

.form input {
    width: 100%;
    padding: 11px 0;
    border: 0;
    border-bottom: 2px solid var(--primary-color);
    font-size: 16px;
    outline: none;
    background: transparent;
}

.row {
    margin-bottom: 20px;
    position: relative;
}

.row label {
    position: absolute;
    z-index: 0;
    top: 11px;
    left: 0;
    transition: all ease-out 250ms;
    cursor: text;
    display: block;
    font-size: 16px;
    color: var(--primary-color);
}

.row input:focus + label {
    top: -18px;
    left: 0;
    font-size: 12px;
    color: #004851;
    transition: all ease-in-out 250ms;
    font-weight: bold;
}

.form button {
    padding: 9px 24px;
    border: none;
    display: inline-block;
    background: var(--primary-color);
    color: #fff;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    transition: all ease-in-out 350ms;
    width: 100%;
}

.form button:hover {
    background: var(--primary-color);
}

.recaptcha {
    margin-bottom: 20px;
}
