.thumbnail {
    width: 139px;
    height: 177px;
    border: 1px solid var(--secondary-color);
    margin-right: 20px;
}

.preview {
    position: absolute;
    display: none;
    z-index: 10000;
    height: 800px;
    background-color: #fff;
    border: 1px solid #ccc;
    top: 50px;
}

.thumbnailLink:hover + .preview {
    display: block !important;
    top: 0;
    margin-left: 150px;
}

.resultPrintedIcon {
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;
    bottom: 35px;
    left: 15px;
}
