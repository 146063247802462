.inzTitle {
    margin: 20px 0 15px 0;
    font-size: 18px;
    color: #ababab;
}

.entryTitle + .inzTitle {
    margin-top: 0 !important;
}

.tableResults {
    width: 100%;
}

.tableResultsTitles {
    background: var(--table-results-box-title);
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-family: 'PT Sans Bold', sans-serif;
    vertical-align: top;
    font-weight: bold;
}

.tableResultsTitles th,
.tableResults tbody td {
    padding: 18px 38px;
    font-size: 16px;
}

.tableResults tbody td {
    color: var(--primary-color);
    border-bottom: 1px solid #ddd;
}

.tableResultsAnalyses {
    width: 30%;
}

.tableResultsResult {
    width: 15%;
}

.tableResultsReference {
    width: 23%;
}

.tableResultsComment {
    width: 35%;
}

.referenceMarker {
    user-select: none;
    width: 15px;
    height: 15px;
    font-size: 11px;
    border-radius: 50%;
    background-color: #f75376;
    cursor: default;
    color: #fff;
    line-height: 14px;
    display: inline-flex;
    justify-content: center;
    margin-right: 3px;
    position: relative;
    bottom: 2px;
}

.referenceValue {
    color: #f75376 !important;
}

.entryTitle {
    font-size: 24px;
    color: var(--h2-entry-color);
}
