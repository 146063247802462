.officeList {
    flex-direction: column;
    height: 100%;
}

.officeListItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    padding: 40px;
    cursor: pointer;
    border-bottom: 1px solid #e8eced;
    height: 212px;
}

.itemBodyContainer {
}

.officeHeader {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}

.officeNameIcon {
    margin-right: 10px;
    height: 40px;
    width: 40px;
}

.officeNameTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: var(--primary-color);
}

.officeSelected .officeNameTitle,
.officeTruncatedSelected .officeNameTitle {
    color: #fff;
}

.officeAddress {
    margin-bottom: 12px;
    color: #13353f;
    font-weight: 600;
}

.officeSelected .officeAddress,
.officeTruncatedSelected .officeAddress {
    color: #fff;
}

.officeTags {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    min-height: 50px;
}

.officeTags::-webkit-scrollbar {
    background-color: #fff;
    width: 15px;
    height: 7px;
}

.officeList > div > div::-webkit-scrollbar {
    background-color: #fff;
    width: 6px;
}

.officeTags::-webkit-scrollbar-track,
.officeTags::-webkit-scrollbar-track:hover,
.officeList > div > div::-webkit-scrollbar-track,
.officeList > div > div::-webkit-scrollbar-track:hover {
    background-color: #fff;
}

.officeTags::-webkit-scrollbar-thumb,
.officeList > div > div::-webkit-scrollbar-thumb {
    background-color: #f6f6f6;
    border-radius: 16px;
}

.officeTags::-webkit-scrollbar-button,
.officeList > div > div::-webkit-scrollbar-button {
    display: none;
}

.officeTag {
    padding: 5px 25px;
    margin-right: 12px;
    margin-bottom: 12px;
    flex: 0 0 auto;
    background: #f6f6f6;
    border-radius: 46px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #13353f;
}

.officeSelected .officeTag {
    background: var(--office-tag-bg-color);
    color: #fff;
}

.officeTruncatedSelected .officeTag {
    background: #ed5e0a;
    color: #fff;
}

.officeTruncatedSelected {
    background: #ff6a13;
}

.officeTruncatedSelected .officeTags::-webkit-scrollbar-track,
.officeTruncatedSelected .officeTags::-webkit-scrollbar-track:hover {
    background-color: #ff6a13;
}

.officeTruncatedSelected .officeTags::-webkit-scrollbar-thumb:hover,
.officeSelected .officeTags::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
}

.officeTruncatedSelected .officeTags::-webkit-scrollbar-thumb,
.officeSelected .officeTags::-webkit-scrollbar-thumb {
    background-color: #ffffff;
}

.officeSelected {
    background: var(--primary-color);
}

.officeSelected .officeTags::-webkit-scrollbar-track,
.officeSelected .officeTags::-webkit-scrollbar-track:hover {
    background-color: var(--primary-color);
}

.officeTruncatedSelected::after,
.officeSelected::after {
    content: '';
    position: absolute;
    right: 12px;
    top: calc(50% - 5px);
    border: 5px solid transparent;
    border-left: 5px solid #fff;
}
