.container {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 38px 38px 76px 38px;
}

.title {
    margin: 0 0 20px;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}

.tableResults,
.preordersHistory {
    margin-top: 10px;
}

.entryTitle {
    font-size: 24px;
    color: var(--h2-entry-color);
}

.status {
    font-size: 18px;
    margin: 0 0 20px;
}

.head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.icon {
    position: relative;
    top: 10px;
    right: 25px;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.repeat {
    color: var(--primary-color) !important;
    border-bottom: var(--primary-color) 1px dotted;
    margin-top: 10px;
    font-size: 16px;
    margin-left: 20px;
}

.repeat:hover {
    border-bottom: var(--secondary-color) 1px dotted;
    cursor: pointer;
}

.table tbody td:first-child .icon {
    position: static;
    display: inline-flex;
    margin-top: 10px;
}

.tooltip {
    font-size: 12px;
    line-height: 14px;
    min-width: 200px;
}

.tooltip div:first-child {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
}

.tooltip div {
    margin-bottom: 10px;
}

.tooltip div:not(:first-child) {
    display: flex;
    justify-content: space-between;
}

.icon:hover,
.addressLink:hover {
    cursor: pointer;
}

.icon table {
    position: relative;
    top: 10px;
    right: 25px;
}

.table {
    margin-top: 10px;
}

.table th {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #5e7f89;
    padding: 15px 0 15px 15px;
    text-align: left;
}

.table tbody tr {
    border-top: 1px solid #dddddd;
    height: 75px;
}

.table tbody tr:hover {
    background: var(--primary-bg-color);
    color: #ffffff !important;
    cursor: pointer;
}

.table tbody td {
    padding: 15px 20px 15px 15px;
    color: #13353f;
}

.actions {
    padding-right: 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.date {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    color: #5e7f89;
}

.statusGreen {
    color: #64a50b;
}

.statusRed {
    color: #ec4453;
    border-bottom: #ec4453 1px dotted;
}

.addressLink {
    color: var(--primary-color) !important;
    margin-left: 20px;
    border-bottom: var(--primary-color) 1px dotted;
}
