.icon {
    position: relative;
    top: 10px;
    right: 25px;
    stroke: #5e7f89;
    fill: #5e7f89;
    outline: none;
}

.icon:hover,
.checkStatus {
    cursor: pointer;
}

.statusBlock {
    min-width: 185px;
}

.checkStatus {
    border-bottom: #cdb108 1px dotted;
}

.checkStatusHint {
    top: 7px;
    left: 5px;
    stroke: #cdb108;
}

.statusOrange {
    color: #cdb108;
}

.statusGreen {
    color: #64a50b;
}

.statusRed {
    color: #ec4453;
    border-bottom: #ec4453 1px dotted;
}

.statusRed:hover {
    cursor: default;
}

.date {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
    color: #5e7f89;
}
