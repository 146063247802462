.reactTabs {
    -webkit-tap-highlight-color: transparent;
}

.reactTabsList {
    border-bottom: 1px solid #e8eced;
    margin: 0 0 10px;
    padding: 0;
}

.reactTabsTab {
    display: inline-block;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.92857;
    border-bottom: 3px solid transparent;
    transition: color .2s,border-color .2s;
}

.reactTabsTabSelected {
    background: #fff;
    color: #ff6a13;
    border-color: #ff6a13;
}

.reactTabsTab:hover {
    color: #ff6a13;
}

.reactTabsTabDisabled {
    color: GrayText;
    cursor: default;
}

.reactTabsPanel {
    display: none;
}

.reactTabsPanelSelected {
    display: block;
}
