.textarea {
    font-weight: 400;
    line-height: 20px;
    min-height: 50px;
    padding: 15px;
    transition: border-color 0.2s;
    border: 1px solid #e8eced;
    color: #13353f;
    font-size: 16px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    appearance: none;
}

.textarea:focus {
    border-color: var(--primary-color);
}

.textarea.error {
    border-color: red;
    color: red;
}
