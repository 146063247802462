.container {
    background: #fff;
    padding: 38px 0 76px 0;
    width: 100%;
}

.title {
    margin: 28px 0 23px 38px;
    font-size: 30px;
    color: var(--h-entry-color);
}

.resultContentBlock {
    margin: 45px 38px 0 38px;
}

.breadcrumbs {
    margin: 0 0 0 38px;
}

.criticalTest {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1.5rem 38px;
    margin: 40px 0 40px 0;
    background: var(--out-reference-box-bg-color);
    color: var(--primary-text-color);
    font-size: 14px;
}

.criticalTestContent {
    margin-left: 10px;
}

.contentBlockHead {
    display: flex;
}

.contentBlockHead svg {
    margin-top: 3px;
}

.blockTitleIcon {
    display: flex;
    margin-right: 10px;
    fill: #ffffff;
}

.sendResultsNoticeIcon {
    margin-right: 10px;
}

.blockTitle,
.blockTitleResultFiles {
    font-size: 24px;
    color: var(--h2-entry-color);
}

.blockTitleResultFiles {
    margin-bottom: 0;
}

.sendResultInput {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    outline: none;
}

.sendResultsCheckbox {
    margin: 20px 0;
}

.sendResultButton {
    margin: 0 0 45px 0;
}

.sendResultsNotice {
    font-family: 'PT Sans Bold', sans-serif;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 14px;
    margin: 20px 0;
    width: 220px;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #fff;
}

.resultsDynamicLink {
    margin-left: 34px;
}

.buttonListOrder {
    display: flex !important;
    margin-left: 38px;
    align-items: center;
}

.buttonListOrder img {
    width: 9px;
    height: 16px;
    display: block;
}

.resultFiles {
    display: flex;
    flex-direction: column;
}

.resultFilesHead {
    height: 50px;
}

.resultFilesContent {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    height: 100%;
    padding-bottom: 5px;
}

.patientCard {
    margin: 0 38px 0 38px;
}

.documentsLogo {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.messagePermissions {
    margin-left: 38px;
}

.referenceMarker {
    user-select: none;
    min-width: 15px;
    height: 15px;
    font-size: 11px;
    border-radius: 50%;
    background-color: #f75376;
    cursor: default;
    color: #fff;
    text-align: center;
    line-height: 14px;
}
