.tableResults {
    width: 100%;
    border-collapse: collapse;
    letter-spacing: 0.6px;
    font-size: 14px;
    color: var(--table-results-text-color);
}

.tableResultsTitle {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: var(--primary-color);
    vertical-align: bottom;
    letter-spacing: 0.3px;
    padding: 11px 10px;
    white-space: nowrap;
}

.tableResultsPatient {
    width: 345px;
}

.tableResultsRow td,
.tableResultsTitle th {
    padding: 11px 10px;
    vertical-align: middle;
}

.tableResultsRow:nth-child(even) {
    background: var(--table-row-bg-even);
}

.tableResultsRow:hover {
    outline: 1px solid var(--primary-color);
    cursor: pointer;
}

.resultPrintedIcon {
    width: 20px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
    stroke-width: 1px;
}

.resultStatus {
    font-weight: bold;
    white-space: nowrap;
}

.statusNotCompleted {
    color: #565555;
}

.statusPartial {
    color: #f38e18;
}

.statusCompleted {
    color: var(--primary-color);
}

.referenceMarker {
    user-select: none;
    width: 15px;
    height: 15px;
    font-size: 11px;
    border-radius: 50%;
    background-color: #f75376;
    cursor: default;
    color: #fff;
    text-align: center;
    line-height: 14px;
}

.tooltip {
    width: 300px;
}

.link {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    color: var(--primary-color);
}

.link:hover {
    text-decoration: none;
}

.link:not(:last-child) {
    margin: 0 30px;
}

.hint {
    background: var(--secondary-color);
    width: 16px;
    height: 16px;
    font-size: 11px;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    margin-left: 5px;
    border-radius: 50%;
}

.hint:hover {
    cursor: pointer;
}
