.container {
    background: #fff;
    padding: 38px 38px 76px 38px;
    width: 100%;
}

.downloadBlock {
    display: flex;
}

.download {
    margin: 20px 20px 30px 0;
}
.xlsxIcon {
    height: 16px;
    width: 16px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 2px;
}

.dynamicsTables {
    display: flex;
    margin-top: 20px;
}

.dynamicsTableNameAnalysis table {
    width: 250px;
}

.dynamicsTableNameAnalysis td {
    text-align: left;
    vertical-align: center;
}

.dynamicsTables th {
    font-family: 'PT Sans Bold', sans-serif;
    font-weight: bold;
    margin-top: 7px;
    margin-left: 5px;
    font-size: 16px;
    line-height: 14px;
    color: var(--primary-color);
}

.dynamicsTableResultsTests {
    width: 100%;
    overflow: auto;
}

.dynamicsTableResultsTests th {
    min-width: 85px;
    font-weight: normal;
}
.dynamicsTableResultsTests th {
    font-size: 12px;
}

.dynamicsTableResultsTests thead tr,
.dynamicsTableNameAnalysis thead tr {
    height: 50px;
}

.dynamicsTableResultsTests tbody tr,
.dynamicsTableNameAnalysis tbody tr {
    height: 40px;
}

.dynamicsTableResultsTests tr,
.dynamicsTableNameAnalysis tr {
    min-width: 100px;
    text-align: center;
}

.dynamicsTableNameAnalysis tr:first-child {
    text-align: left;
}

.dynamicsTables td {
    vertical-align: middle;
    padding: 2px 6px;
    white-space: nowrap;
}

.dynamicsTableNameAnalysis tbody tr:nth-child(even),
.dynamicsTableResultsTests tbody tr:nth-child(even) {
    background: var(--table-row-bg-even);
}

.dynamicsTables tbody tr:hover {
    background: #fff8d6 !important;
    cursor: pointer;
}

.testIcon {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin-right: 5px;
    margin-left: 5px;
    stroke: none;
}

.dynamicsTableCell {
    font-size: 14px;
    color: var(--primary-color);
}

.dynamicsTableTitle {
    text-decoration: underline;
}

.dynamicsTableUnit {
    display: inline-block;
    word-wrap: break-word;
    color: var(--primary-color);
}

.dynamicsResultsNotice {
    font-size: 13px;
    margin: 20px 0;
}

.dynamicsOutsideMarker {
    color: #de2222;
}

.tableItemsDate {
    font-size: 12px;
}

.tableItemsDate div:first-child {
    font-weight: bold;
    margin-bottom: 10px;
}

.outbound {
    color: #d90000;
}

.dynamicsCommentIcon {
    position: relative;
    top: 3px;
    margin-left: 5px;
}
