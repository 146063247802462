.container {
    font-family: 'PT Sans Regular', sans-serif;
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 16px;
    color: var(--primary-color);
}

.text {
    font-weight: bold;
}

.phoneLink {
    text-decoration: none;
    vertical-align: middle;
}

.phoneIcon {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
}

.phoneNumber {
    color: #5e7f89;
}
