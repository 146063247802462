.container {
    background: #fff;
    width: 100%;
}

.content {
    background: #fff;
    padding: 38px 38px 76px 38px;
    width: 100%;
}

.pageHead {
    display: flex;
    justify-content: space-between;
}

.title {
    margin: 0 0 20px;
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}

.searchPanel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 40px;
}

.searchPanel input {
    padding: 10px 17px;
    border: 1px solid #ddd;
}

.searchPanel input::placeholder {
    color: var(--primary-color);
}

.searchPanel button {
    display: flex;
    padding: 12px 15px;
    border: none;
    background: var(--primary-color);
    cursor: pointer;
    transition: all ease-in-out 350ms;
    min-width: auto;
}

.searchPanel button[disabled] {
    pointer-events: none;
    cursor: default;
    background: #ddd;
    border: none;
}

.clearSearchStringIcon {
    position: absolute;
    right: 60px;
}

.clearSearchStringIcon:hover {
    cursor: pointer;
}

.searchStringIcon {
    width: 19px;
    height: 17px;
    display: inline-block;
    fill: #ffffff;
}

.newsList {
    margin-top: 30px;
}

.newsList li {
    position: relative;
}

.newsList li:not(:last-child) {
    margin-bottom: 25px;
}

.newsListItem {
    color: var(--primary-color);
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    margin-right: 5px;
}

.newsListItem:hover {
    text-decoration: underline;
}

.new {
    background: #fffbe8;
}

.newsListItemDate {
    color: #999 !important;
    font-style: italic;
    font-size: 11px;
    line-height: 16px;
    white-space: nowrap;
}

.importantNewsIcon {
    position: absolute;
    top: 5px;
    left: -22px;
}
