.container {
    background: #fff;
    padding: 38px 38px 76px 38px;
    width: 100%;
}

.title {
    margin: 0 0 20px;
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}

.applyPeriod,
.actionButton {
    width: 220px;
    padding: 7px 24px;
    border: 2px solid transparent;
    display: inline-block;
    background: var(--primary-color);
    color: #fff;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    transition: all ease-in-out 350ms;
    border-radius: 0;
    letter-spacing: 0.6px;
    margin: 30px 10px 10px 10px;
}

.actionButton {
    margin-top: 20px;
}

.actionButton:after {
    content: '';
    display: inline-block;
    margin-left: 8px;
    margin-bottom: 1px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid #fff;
}

.actionList {
}

.actionListItem:hover {
    background: #fff8d6;
}

.disabled,
.disabled:hover {
    cursor: default;
    opacity: 0.5;
    background: #ffffff;
}

.actionButton:first-child {
    margin-left: 0;
}

.actionButton:hover,
.applyPeriod:hover {
    background: var(--primary-color);
}

.actions,
.actionControls {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.action {
    display: flex;
    position: relative;
    flex-direction: column;
}

.actionButtonDisabled {
    border: 2px solid #e2e3e3;
    background: transparent;
    color: #cdcecf;
    pointer-events: none;
}

.actionList {
    display: none;
    position: absolute;
    z-index: 3;
    background: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    box-shadow: 0 9px 12px rgb(0 0 0 / 30%);
    transition: all ease-in-out 350ms;
    animation: fade ease-in-out 350ms;
    width: 220px;
    top: 61px;
}

.actionListActive {
    display: block !important;
}

.actionListItem {
    padding: 11px 10px;
    cursor: pointer;
    font-size: 13px;
}
