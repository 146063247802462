.footer {
    display: flex;
    flex: 0 0 auto;
    background: #ffffff;
    justify-content: center;
    margin-top: 50px;
    height: 100px;
    padding: 0 15px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    align-self: center;
    height: 75px;
}

.copy {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    color: var(--copyright-text-color);
}

.copyText {
    margin-top: 5px;
}
