.container {
    background: #fff;
    width: 100%;
    padding: 23px 0 0 0;
}

.breadcrumbs {
    margin: 0 0 0 38px;
}

.preload {
    padding: 38px 38px 76px 38px;
}

.messagePermissions {
    margin-left: 38px;
}

.downloadIcon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.downloadLInk {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    right: 25px;
    bottom: 25px;
    text-transform: uppercase;
    margin-right: 10px;
    padding: 7px;
}

.downloadLInk:hover {
    color: var(--primary-color);
    text-decoration: none;
    background: #4b4b4b;
    border-radius: 100px;
    padding: 7px;
}

.document {
    display: flex;
    background: #414141;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: center;
    padding: 50px;
}

.page {
    margin-top: 30px;
}

.toolbar {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 80px;
}
