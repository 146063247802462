.permissions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.permissions span {
    margin: 5px 0;
}

.permission:not(:first-child) {
    margin-top: 5px;
}