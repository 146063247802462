.button {
    display: flex;
    align-items: center;
}

.button svg {
    width: 16px;
    height: 16px;
    stroke: #0097b3;
    fill: transparent;
    transition: all .3s;
}

.button:hover svg {
    stroke: #FF6A13;
}

.button span {
    margin-left: 8px;
}
