.container {
    background: #fff;
    width: 100%;
}

.content {
    padding: 40px 40px 80px 40px;
}

.title {
    margin: 0 0 20px;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}

.pageDescription p {
    font-size: 16px;
    letter-spacing: 0.4px;
    color: #272727;
}

.materialsComment {
    margin-top: 40px;
}

.materialsCommentTitle {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}

.materialsCommentField {
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 16px;
    width: 100%;
    height: 100px;
    overflow: auto;
    resize: none;
    padding: 10px;
    margin-top: 10px;
}

.materialsToolbar {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}

.materialsToolbarItem:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.materialsToolbarItem:last-child {
    display: flex;
    align-items: center;
}

.materialsToolbar input {
    width: 463px;
    height: 40px;
    background: #fff;
    border: none;
    padding: 0 33px 0 17px;
    font-size: 18px;
}

.materialsToolbar input::placeholder {
    color: var(--primary-color);
}

.clearInputIcon {
    width: 11px;
    height: 10px;
    position: relative;
    right: 25px;
}

.clearInputIcon:hover {
    cursor: pointer;
}

.materialsToolbarTotal {
    width: 100%;
    margin-right: 20px;
    color: #fff;
    letter-spacing: 0.4px;
    font-size: 18px;
    font-family: 'PT Sans Bold', sans-serif;
    text-align: right;
}

.materialsToolbarButtons {
    height: 40px;
    color: #fff;
    letter-spacing: 0.6px;
    font-size: 16px;
    transition: all ease-in-out 150ms;
    display: flex;
}

.materialsToolbarButtons button {
    min-width: 150px;
    width: 100%;
}

.materialsToolbarButtons button:hover {
    background: #e18f4a !important;
}

.materialsToolbarButtons button:first-child {
    border-color: #fff;
    color: #fff;
    background: transparent;
    margin-right: 20px;
}

.materialsToolbarButtons button:last-child {
    background: #e07826;
}

.confirmOrderModal,
.notAvailableMaterialsModal {
    color: #000000;
}

.confirmOrderModal h1,
.notAvailableMaterialsModal h3 {
    padding-bottom: 20px;
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
    color: var(--primary-color);
}

.notAvailableMaterialsModal h3 {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.confirmOrderListWrap {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}

.confirmOrderList {
    margin: 0 20px;
}

.notAvailableMaterialsModal .confirmOrderList {
    margin: 30px 0;
}

.notAvailableMaterialsButton {
    text-align: center;
}

.confirmOrderListTitle {
    width: 510px;
}

.confirmOrderListValue {
    width: 105px;
}

.confirmOrderList th {
    color: #888b8d;
    text-align: left;
    font-weight: normal;
}

.confirmOrderList td {
    padding: 10px 0 10px 0;
}

.confirmOrderTotal {
    font-weight: bold;
}

.confirmOrderTotal td {
    padding-top: 20px;
}

.confirmOrderAddress {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 30px;
}

.confirmOrderAddress span {
    margin-right: 10px;
}

.confirmOrderAddress p {
    font-weight: normal;
}

.confirmOrderButtons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.confirmOrderButtons button {
    min-width: auto;
    height: 40px;
}

.confirmOrderButtons button:first-child {
    display: inline-block;
    outline: none;
    background: transparent;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    border-radius: 0;
    border: 2px solid var(--primary-color);
    transition: all ease-in-out 350ms;
}

.confirmOrderButtons button:first-child:hover {
    background: var(--primary-color);
    color: #fff;
}

.confirmOrderButtons button:last-child {
    background: var(--order-button-bg-color);
    margin-left: 10px;
}

.confirmOrderButtons button:last-child:hover {
    background: var(--order-button-hover-bg-color) !important;
}

.messagePermissions {
    margin-left: 38px;
    margin-top: 28px;
}

.searchPanel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin-top: 30px;
}

.searchPanel input {
    width: 100%;
    padding: 10px 17px;
    border: 1px solid #ddd;
}

.searchPanel input::placeholder {
    color: var(--primary-color);
}

.searchPanel button {
    display: flex;
    padding: 12px 15px;
    border: none;
    background: var(--primary-color);
    cursor: pointer;
    transition: all ease-in-out 350ms;
    min-width: auto;
}

.searchPanel button[disabled] {
    pointer-events: none;
    cursor: default;
    background: #ddd;
    border: none;
}

.clearSearchStringIcon {
    position: absolute;
    right: 60px;
}

.clearSearchStringIcon:hover {
    cursor: pointer;
}

.searchStringIcon {
    width: 19px;
    height: 17px;
    display: inline-block;
}
