.container {
    background: #fff;
    width: 100%;
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 14px;
    line-height: 1.4;
}

.container h2 {
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    margin: 0 0 20px 0;
}

.settingBlock:not(:last-child) {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.settingBlock div:first-child {
    flex: 0 0 270px;
}

.settingRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.passwordForm,
.phoneForm,
.emailForm {
    max-width: 460px;
    text-align: right;
}

.passwordForm input {
    width: 250px;
}

.listTitle {
    margin: 20px 0;
}

.container ul {
    list-style-type: disc;
    margin-left: 50px;
    margin-bottom: 30px;
}

.container button {
    min-width: 178px;
}

.recaptcha {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.noticeBlock {
    margin-bottom: 20px;
}
