.container {
    width: 100%;
}

.title {
    margin: 70px 0 25px 67px;
    font-size: 36px;
    color: var(--h-entry-color);
}

.content {
    display: flex;
    height: 552px;
    background-color: #fff;
    justify-content: var(--form-justify-content);
}

.error {
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: #f75376;
    position: relative;
    top: -10px;
}

.form {
    margin: 70px;
    min-width: 300px;
}

.row {
    margin-bottom: 20px;
    position: relative;
}

.photo {
    z-index: 1;
    width: 484px;
    height: 595px;
    background: url(authImage.png) no-repeat;
    position: relative;
    top: -43px;
    right: 65px;
}

.form button {
    width: 100%;
    padding: 9px 24px;
    border: 2px solid var(--primary-color);
    display: inline-block;
    outline: none;
    background: transparent;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    border-radius: 0;
    transition: all ease-in-out 350ms;
}

.form button:hover {
    background: var(--primary-color);
    color: #fff;
}

.recoveryLink {
    text-align: center;
}

.recaptcha {
    margin-bottom: 20px;
}

.recaptchaError {
    border: 2px solid red;
}
