.container {
    display: flex;
    width: 100%;
    margin: 15px 0;
}

.loadingBlock {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.loadingIcon {
    margin-right: 5px;
    animation: loading linear 500ms infinite;
}

.loadingText {
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 12px;
    line-height: 1.4;
    margin: 0 !important;
}

@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.transparent {
    width: auto !important;
    background: transparent !important;
}

.overlay:after {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 1000ms;
    content: '';
    display: inline-block;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
