.button {
    color: var(--primary-color);
    background-color: #fff;
}

.button:hover {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.button:disabled {
    color: var(--primary-color);
    background-color: #afafaf;
    border-color: #afafaf;
}
