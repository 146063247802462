.container {
    display: flex;
    margin: 0 38px 23px 0;
}

.patientInfoItems {
    max-width: 830px;
    display: flex;
    font-size: 16px;
    letter-spacing: 1px;
    justify-content: space-between;
}

.patientInfoRow {
    display: flex;
    flex-direction: column;
}

.patientInfoRow:first-child {
    margin-right: 50px;
}

.patientInfoItem {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 18px;
}

.patientInfoOfficeName {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 18px;
    white-space: nowrap;
}

.patientInfoTitle {
    color: var(--primary-color);
}

.patientInfoDesc {
    margin-left: 5px;
}
