.cartContainer {
    padding: 0;
}

.cartContainer .cartContent {
    display: flex;
    justify-content: space-between;
    margin: -24px -12px 0 -12px;
}

.cartContainer .cartContent .cartProducts {
    position: relative;
    margin-top: 24px;
    margin-left: 12px;
    margin-right: 12px;
    flex-grow: 2;
    flex-basis: 0;
}

.cartContainer .cartContent .cartProducts .cartClear {
    display: block;
    position: absolute;
    top: -47px;
    right: 20px;
    color: #13353f;
    opacity: 0.5;
    padding-right: 40px;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.27, 0.67, 0.41, 1.06);
    font-size: 14px;
    font-family: Open Sans, sans-serif;
}

.cartContainer .cartContent .cartProducts .cartClear:hover {
    opacity: 1;
}

.cartContainer .cartContent .cartProducts .cartClear span {
    border-bottom: 1px dashed #13353f;
}
.cartContainer .cartContent .cartProducts .cartClear svg {
    position: absolute;
    right: 10px;
    top: 5px;
    fill: #13353f;
    stroke: #13353f;
}

.cartContainer .cartContent .cartProducts ul {
    background-color: white;
}

.cartContainer .cartContent .cartProducts ul li {
    padding: 12px 0 12px;
}

.cartContainer .cartContent .cartProducts ul li + li {
    border-top: 1px solid #e8eced;
}

.cartContainer .cartContent .cartSummary {
    margin-top: 24px;
    margin-right: 12px;
    margin-left: 12px;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 415px;
}

.cartContainer .cartContent .cartSummary .floating {
    position: sticky;
    top: -1px;
}

.unavailableProductsContainer {
    background: white;
    border: 2px solid #ff6a13;
    margin-bottom: 30px;
    padding: 0 24px 24px 24px;
}

.unavailableProductsWarning {
}

.unavailableProductsActionsBlock button {
    background: none;
    border: 2px solid var(--primary-color);
}

.unavailableProductsActionsBlock button:first-child {
    margin-right: 20px;
}

.retryButton {
    margin-left: 10px;
}

.cartWarning {
    margin-top: 0;
}