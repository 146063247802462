.form input[type='text'],
.form input[type='number'],
.form select,
.row input {
    padding: 0 12px;
    height: 40px;
    border: 1px solid #dddddd;
    width: 100%;
}

.form input[type='checkbox'] {
    border: 1px solid #dddddd;
}

.form .button {
    width: 100%;
    margin: 20px 0;
}

.row {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.row label span {
    color: red;
    padding-left: 5px;
}

.patientList {
    margin: 30px 0;
    max-height: 700px;
    overflow-x: auto;
    border: 1px solid var(--settings-aside-bg-color);
}

.patient:nth-child(2n) {
    background: var(--table-row-bg-even);
}

.patient {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}

.patient button {
    width: 180px;
    min-width: auto;
}

.patientData {
    text-align: center;
}

.selectedButton {
    background: var(--order-button-bg-color) !important;
}

.modals {
    width: 1200px;
    height: 100%;
}
