.container {
    display: flex;
    height: 50px;
    padding: 0 38px;
    border-bottom: 2px solid #e9f4f6;
    background: #fff;
    align-items: center;
    margin-top: 10px;
}

.menu {
    display: flex;
    align-items: flex-start;
    width: 100%;
    overflow-y: auto;
}

.menuItem {
    display: flex;
    background: #ffffff;
    align-items: center;
}

.menuItem:not(:last-child) {
    margin-right: 0;
}

.menuItem a {
    display: flex;
    align-items: center;
    padding: 7px 13px;
    cursor: pointer;
    background: #fff;
    color: #888b8d !important;
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
}

.menuItem a {
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px 10px;
    cursor: pointer;
    background: #fff;
    color: #000;
    font-size: 16px;
    text-decoration: none !important;
}

.menuItem a.activeMenu {
    border-bottom: var(--secondary-color) 3px solid;
    color: var(--primary-color) !important;
}

.menuItem a:not(.activeMenu) {
    color: var(--submenu-notactive-color) !important;
}

.menuItem a img:not(:first-child) {
    display: none;
}

.activeMenu img:first-child {
    display: none;
}

.activeMenu img:last-child {
    display: inline-block !important;
}

.messageCounter {
    font-family: 'PT Sans Bold', sans-serif;
    position: relative;
    top: -10px;
    right: 0;
    min-width: 25px;
    border-radius: 50%;
    background: var(--message-counter-color);
    color: #fff;
    line-height: 17px;
    letter-spacing: 0.7px;
    font-size: 10px;
    text-align: center;
    padding: 3px;
}
