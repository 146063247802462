.header {
    height: 100px;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    background: #ffffff;
}

@media print {
    .header {
        display: none;
    }
}

.inner {
    width: 1200px;
    margin: 0 15px;
    display: flex;
    justify-content: space-between;
}

.innerLogo {
    display: flex;
    align-items: center;
}

.logo {
    margin-right: 10px;
}

.logo,
.logoTitle {
    height: 33px;
}

.headerInfo {
    display: flex;
    max-width: 1200px;
    align-self: center;
    width: 100%;
    justify-content: space-between;
    margin: 30px 0;
    height: 150px;
    padding: 0 15px 0 15px;
}

@media print {
    .headerInfo {
        display: none;
    }
}

.banners {
    display: flex;
    height: auto;
    width: auto;
    margin-left: 10px;
}
