.container {
    background: #fff;
    width: 100%;
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 14px;
    line-height: 1.4;
}

.title {
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
    margin: 0 0 20px;
}

.container p {
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 12px;
    line-height: 1.4;
}

.officeGroupList {
    margin-top: 30px;
    padding-top: 10px;
}

.officeGroupList th {
    font-weight: bold;
}

.officeGroupList td:first-child {
    width: 70%;
}

.officeGroupList td {
    min-height: 32px;
    padding: 10px;
    font-size: 13px;
    line-height: 17px;
}

.officeGroupList tbody tr:hover {
    background-color: #fff8d6 !important;
    cursor: pointer;
}

.officeGroupList tr:nth-child(even) {
    background: #f5f5f5;
    cursor: pointer;
}

.officeGroupList tr span {
    font-style: italic;
}

.actionModal {
    margin-bottom: 30px;
}

.actionButtons {
    display: flex;
    justify-content: space-between;
}

.marker {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: 14px;
    height: 14px;
    min-width: 14px;
    font-size: 11px;
    border-radius: 50%;
    border: 1px solid var(--primary-color);
    cursor: default;
    color: var(--primary-color);
    text-align: center;
    line-height: 14px;
    margin-left: 5px;
}
