@keyframes slideLeftToCenter {
    100% {
        left: 0;
    }
}

@keyframes slideRightToCenter {
    100% {
        right: 0;
    }
}

.overlay {
    padding: 72px 0;
    scroll-behavior: smooth;
}

.modal {
    max-width: 1112px;
    padding: 48px 42px 0 42px !important;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100%;
    width: 100%;
}

.modal > button {
    background-color: #f6f6f6;
    width: 48px !important;
    height: 48px;
    border-radius: 100px;
    line-height: 28px;
    z-index: 1;
}

.modal > button:hover {
    background-color: #e7e7e7;
}

.title {
    font-family: Gilroy, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 34px;
    color: #13353f;
    margin-right: 80px;
}

.interpretationWarning {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #13353f;
    margin-top: 24px;
}

.modal .interpretation a {
    color: #0097b3;
}

.modal .interpretation a:hover {
    color: #ff6a13;
}

.root {
    box-sizing: border-box;
    padding-bottom: 100px;
    font-family: 'PT Sans', sans-serif;
    -webkit-tap-highlight-color: transparent;
}

.root ul[role='tablist'] {
    border: none;
    margin-top: 24px;
    white-space: normal;
    overflow-x: auto;
    overflow-y: hidden;
    height: 60px;
}

.root ul[role='tablist'] {
    white-space: nowrap;
}

.root ul[role='tablist'] li {
    text-align: left;
}

.tab {
    list-style: none;
    cursor: pointer;
    font-family: Gilroy, sans-serif;
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    color: #0097b3;
    transition: color 0.2s;
    padding: 15px 30px !important;
    background: #f2f9fa;
    border-radius: 90px;
    border: none;
}

.tab:hover {
    color: #21b4d0;
}

.tabDisabled {
    color: GrayText;
    cursor: default;
}

.panel {
    display: none;
}

.panelSelected {
    display: block;
}

.tabs {
    border-bottom: 1px solid #e8eced;
    margin-bottom: 15px;
    padding-bottom: 20px;
    white-space: nowrap;
    overflow-x: auto;
}

.tab:not(:last-child) {
    margin-right: 8px;
}

.tab:hover {
    color: #21b4d0;
}

.tabSelected {
    background: #13353f;
    color: #ffffff;
}

.tabSelected:hover {
    color: #eaeaea;
}
