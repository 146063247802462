.modal {
    min-width: 100%;
    min-height: 100%;
    padding: 0;
}

.modal > button {
    display: inline-block;
    top: 15px;
}

.top {
    padding: 31px 48px;
    display: flex;
    align-items: baseline;
}

.linkBackIcon {
    cursor: pointer;
    display: none;
}

.linkBackIcon:hover {
    cursor: pointer;
}

.container {
    font-family: Open Sans, sans-serif;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: 'Gilroy ExtraBold', sans-serif;
    font-size: 30px;
    line-height: 34px;
    color: #13353f;
    font-weight: 800;
}

.buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: center;
    margin-bottom: 30px;
}

.stepContainer > span {
    margin: 0 10px;
}

.btnPrev {
}

.btnNext {
}

.products {
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    margin-right: 32px;
}

.product {
    display: flex;
    margin-right: 16px;
    margin-top: 12px;
}

.article {
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #13353f;
    opacity: 0.5;
    min-width: 92px;
    max-width: 92px;
    word-break: break-word;
    padding-right: 5px;
}

.productName {
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: #13353f;
}

.biomaterials {
    display: flex;
    margin: 48px 32px 0 54px;
    flex-wrap: wrap;
}

.biomaterial {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--primary-color);
    display: flex;
    margin-top: 5px;
    padding: 5px 6px 5px 6px;
    width: 33%;
    height: 100%;
}

.biomaterial input {
    width: 16px;
    height: 16px;
    background: #ffffff;
    border: 1px solid #c4ced1;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 9px;
}

.biomaterial input:hover,
.biomaterial label:hover {
    cursor: pointer;
}

.biomaterial input[type='radio'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 7px;
}
.biomaterial input[type='radio'] + label {
    position: relative;
    padding: 0 0 0 30px;
    cursor: pointer;
}
.biomaterial input[type='radio'] + label:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #c4ced1;
    border-radius: 50%;
    background: #fff;
}

.biomaterial input[type='radio'] + label:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary-color);
    opacity: 0;
    transition: 0.2s;
}

.biomaterial input[type='radio']:checked + label:after {
    opacity: 1;
}

.biomaterial.selected {
    background: var(--primary-color);
    color: white;
}
