.article {
    margin-left: 25px;
    margin-right: 25px;
    font-size: 18px;
    font-family: 'PT Sans Bold', sans-serif;
    color: var(--primary-color);
    letter-spacing: 0.4px;
    text-align: left;
}

.description {
    margin-top: 8px;
    margin-left: 25px;
    margin-right: 25px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    font-size: 17px;
    text-align: left;
}
