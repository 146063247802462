.filter {
    display: flex;
    flex-direction: column;
    padding: 2px;
    background: var(--filter-results-bg-color);
    position: relative;
}

.searchString {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.searchStringIcon {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 19px;
    height: 18px;
}

.searchString input {
    width: 100%;
    height: 40px;
    padding: 10px 30px 10px 40px;
    border: none;
}

.searchString input::placeholder {
    color: var(--primary-color);
}

.searchString input:focus,
.searchString input:active {
    outline: none;
}

.clearSearchStringIcon {
    position: absolute;
    right: 12px;
}

.clearSearchStringIcon:hover {
    cursor: pointer;
}

.periodsWrapper {
    display: flex;
    align-items: center;
    padding: 4px 13px;
    background: var(--filter-results-bg-color);
    color: #ffffff;
    font-size: 13px;
}

.periodButton {
    display: flex;
    align-items: center;
}

.periodButton:hover {
    cursor: pointer;
}

.iconPeriodArrow {
    font-size: 16px;
    color: #fff;
    padding-left: 2px;
}

.periodPopup {
    width: 240px;
    display: none;
    position: absolute;
    z-index: 3;
    top: 70px;
    left: 16px;
    background: #fff;
    box-shadow: 0 1px 7px 0 var(--box-shadow-color);
}

.periodValues {
}

.periodDayValues div {
    padding: 6px 10px;
    color: #000;
    cursor: pointer;
}

.periodDayValues div:hover,
.actionListItem:hover {
    background: #fff8d6;
}

.periodFromTo {
    font-weight: bold;
    color: var(--primary-color);
    border-top: 1px solid #ddd;
    padding: 0 10px;
}

.periodFrom,
.periodTo {
    display: flex;
    flex-direction: column;
}

.periodFrom input,
.periodTo input {
    width: 100%;
}

.periodTitle {
    margin-top: 10px;
    padding-bottom: 5px;
}

.monthYear {
    width: 100%;
    margin-right: 3px;
}

.showPeriodPopup {
    display: block;
    transition: all ease-in-out 200ms;
}

.checkboxes {
    display: flex;
    align-items: baseline;
}

.checkboxes input {
    align-self: center;
    margin-left: 20px;
}

.checkboxes {
    display: flex;
    align-items: baseline;
}

.checkboxes input {
    align-self: center;
    margin-left: 20px;
}

.applyPeriod {
    width: 220px;
    padding: 7px 24px;
    border: 2px solid transparent;
    display: inline-block;
    background: var(--primary-color);
    color: #fff;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    transition: all ease-in-out 350ms;
    border-radius: 0;
    letter-spacing: 0.6px;
    margin: 30px 10px 10px 10px;
}

.notValidDate {
    border: 1px solid red;
}
