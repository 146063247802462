.container {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.aside {
    flex: 0 0 250px;
    background: var(--settings-aside-bg-color);
}

.asideTitle {
    margin: 30px 0 20px 40px;
    font-size: 30px;
    color: var(--primary-color);
}

.aside a {
    font-family: 'PT Sans', sans-serif;
    margin-left: 20px;
    padding: 10px 20px;
    display: block;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
}

.activePageLink {
    background: #fff;
    color: #000;
    text-decoration: none;
    pointer-events: none;
}

.content {
    background: #fff;
    padding: 38px 38px 76px 38px;
    width: 100%;
}
