.input {
    width: 100%;
    padding: 11px 0;
    border: 0;
    border-bottom: 2px solid var(--primary-color);
    font-size: 16px;
    outline: none;
    background: transparent;
}

.error {
    border-color: red !important;
    color: red !important;
}

.error:-webkit-autofill {
    -webkit-text-fill-color: red !important;
}

.input + label {
    position: absolute;
    z-index: 0;
    top: 11px;
    left: 0;
    transition: all ease-out 250ms;
    cursor: text;
    display: block;
    font-size: 16px;
    color: var(--primary-color);
}

.input:focus + label,
.input:-webkit-autofill + label {
    top: -18px;
    left: 0;
    font-size: 12px;
    color: var(--primary-color);
    transition: all ease-in-out 250ms;
    font-weight: bold;
}

.labelFloat {
    top: -18px !important;
    left: 0 !important;
    font-size: 12px !important;
    color: var(--primary-color) !important;
    transition: all ease-in-out 250ms !important;
    font-weight: bold;
}
