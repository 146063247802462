.container {
}

.input {
    width: 100%;
    display: inline-block;
    position: relative;
}

.input input {
    padding-right: 45px;
}

.visibilityBtn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-15px, -50%);
    width: 25px;
    height: 25px;
    display: inline-block;
}

.visibilityBtn:hover {
    cursor: pointer;
}

.strengthLine {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background-color: #ff6913;
    width: 100%;
    transition: transform 0.2s, -webkit-transform 0.2s;
    transform-origin: left;
}

.strengthText {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.71429;
}

.passwordStrength0 {
    color: #ec0000;
}
.passwordStrength1 {
    color: #fe6060;
}
.passwordStrength2 {
    color: #fb8b8b;
}
.passwordStrength3 {
    color: #239cee;
}
.passwordStrength4 {
    color: #299d00;
}

.invisible {
    display: none;
}
