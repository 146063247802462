.container {
    background: #fff;
    width: 100%;
}

.content {
    padding: 40px 40px 80px 40px;
}

.title {
    margin: 0 0 20px;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}

.pageDescription p {
    font-size: 16px;
    letter-spacing: 0.4px;
    color: #272727;
}

.materialsBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
    margin-top: 40px;
}

.materialsBlockItems {
    display: flex;
    flex-direction: column;
}

.materialsBlockItem {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: 0.2px solid var(--secondary-color);
    transition: all ease-in-out 350ms;
    background-color: var(--material-item-bg-color);
    box-shadow: 0 0 5px 0 var(--secondary-color);
}

.materialsBlockItem:not(:last-child) {
    margin-bottom: 17px;
}

.materialListItem {
    display: flex;
    flex-direction: column;
    height: 243px;
}

.materialItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 23px 18px 23px 29px;
    border: 0.5px solid var(--secondary-color);
    background: #ffffff;
}

.materialBlockHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 42px 0 29px;
}

.arrowDown {
    transform: rotate(-90deg);
}

.arrowUp {
    transform: rotate(-270deg);
}

.materialBlockHead h1 {
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    letter-spacing: 0.4px;
    font-weight: normal;
    margin: 0;
}

.materialItemPhoto {
    width: 225px;
    height: 195px;
    margin-right: 10px;
}

.materialItemPhoto img {
    height: 195px;
    width: 225px;
    margin: auto;
    display: block;
    cursor: pointer;
}

.tooltip {
    display: flex;
    flex-direction: column;
    width: 500px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 20px 0 rgb(156 156 156 / 50%);
    transition: all ease-in-out 350ms 1s;
    opacity: 1 !important;
    text-align: center;
    padding: 20px !important;
}

.tooltip img {
    width: 100%;
}

.article {
    margin-left: 25px;
    margin-right: 25px;
    font-size: 18px;
    font-family: 'PT Sans Bold', sans-serif;
    color: var(--primary-color);
    letter-spacing: 0.4px;
    text-align: left;
}

.description {
    margin-top: 8px;
    margin-left: 25px;
    margin-right: 25px;
    color: #4e4e4e;
    letter-spacing: 0.4px;
    font-size: 17px;
    text-align: left;
}

.materialItemTitle {
    color: var(--primary-color);
    font-size: 16px;
    flex-grow: 1;
}

.quantityMaterialOrder {
    width: 137px;
    height: 22.5px;
    padding: 0 20px;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: inset 0 0 2px 0 var(--secondary-color);
    transition: all ease-in-out 250ms;
    font-size: 16px;
    letter-spacing: 0.4px;
    color: var(--primary-color);
    text-align: center;
    -moz-appearance: textfield;
}

.quantityMaterialOrder:focus {
    border: 1px solid var(--primary-color);
}

.quantityMaterial {
    display: flex;
    align-items: center;
}

.buttonMinusIcon,
.buttonPlusIcon {
    font-size: 22px;
    color: var(--primary-color);
    position: relative;
    user-select: none;
}

.buttonMinusIcon {
    left: 15px;
}

.buttonPlusIcon {
    left: -20px;
}

.materialsComment {
    margin-top: 40px;
}

.materialsCommentTitle {
    font-size: 16px;
    font-weight: bold;
}

.materialsCommentField {
    border: 1px solid #ddd;
    background-color: #fff;
    font-size: 16px;
    width: 100%;
    height: 100px;
    overflow: auto;
    resize: none;
    padding: 10px;
    margin-top: 10px;
}

.materialsToolbar {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}

.materialsToolbarItem:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.materialsToolbarItem:last-child {
    display: flex;
    align-items: center;
}

.materialsToolbar input {
    width: 463px;
    height: 40px;
    background: #fff;
    border: none;
    padding: 0 33px 0 17px;
    font-size: 18px;
}

.materialsToolbar input::placeholder {
    color: var(--primary-color);
}

.clearInputIcon {
    width: 11px;
    height: 10px;
    position: relative;
    right: 25px;
}

.clearInputIcon:hover {
    cursor: pointer;
}

.materialsToolbarTotal {
    width: 100%;
    margin-right: 20px;
    color: #fff;
    letter-spacing: 0.4px;
    font-size: 18px;
    font-family: 'PT Sans Bold', sans-serif;
    text-align: right;
}

.materialsToolbarButtons {
    height: 40px;
    color: #fff;
    letter-spacing: 0.6px;
    font-size: 16px;
    transition: all ease-in-out 150ms;
    display: flex;
}

.materialsToolbarButtons button {
    min-width: 150px;
    width: 100%;
}

.materialsToolbarButtons button:hover {
    background: #e18f4a !important;
}

.materialsToolbarButtons button:first-child {
    border-color: #fff;
    color: #fff;
    background: transparent;
    margin-right: 20px;
}

.materialsToolbarButtons button:last-child {
    background: #e07826;
}

.confirmOrderModal {
    color: #000000;
}

.confirmOrderModal h1 {
    padding-bottom: 20px;
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
}

.confirmOrderList {
    margin: 0 20px;
}

.confirmOrderListTitle {
    width: 510px;
}

.confirmOrderListValue {
    width: 105px;
}

.confirmOrderList th {
    color: #888b8d;
    text-align: left;
    font-weight: normal;
}

.confirmOrderList td {
    padding: 10px 0 10px 0;
}

.confirmOrderTotal {
    font-weight: bold;
}

.confirmOrderTotal td {
    padding-top: 20px;
}

.confirmOrderAddress {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 30px;
}

.confirmOrderAddress span {
    margin-right: 10px;
}

.confirmOrderAddress p {
    font-weight: normal;
}

.confirmOrderButtons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.confirmOrderButtons button {
    min-width: auto;
}

.confirmOrderButtons button:first-child {
    display: inline-block;
    outline: none;
    background: transparent;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    border-radius: 0;
    border: 2px solid var(--primary-color);
    transition: all ease-in-out 350ms;
}

.confirmOrderButtons button:first-child:hover {
    background: var(--primary-color);
    color: #fff;
}

.confirmOrderButtons button:last-child {
    background: #e07826;
    margin-left: 10px;
}

.confirmOrderButtons button:last-child:hover {
    background: #e18f4a !important;
}

@keyframes messageRoundQuantity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.roundQuantityAction {
    animation: messageRoundQuantity 4s forwards;
}

:global(.roundQuantity) {
    color: #f75376;
    opacity: 0;
}

.searchPanel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    height: 40px;
    margin-bottom: 30px;
}

.searchPanel input {
    width: 270px;
    height: 40px;
    padding: 10px 17px;
    border: 1px solid #ddd;
}

.searchPanel input::placeholder {
    color: var(--primary-color);
}

.searchPanel button {
    height: 40px;
    display: flex;
    padding: 12px 15px;
    border: none;
    background: var(--primary-color);
    cursor: pointer;
    transition: all ease-in-out 350ms;
    min-width: auto;
}

.searchPanel button[disabled] {
    pointer-events: none;
    cursor: default;
    background: #ddd;
    border: none;
}

.clearSearchStringIcon {
    position: absolute;
    right: 60px;
}

.clearSearchStringIcon:hover {
    cursor: pointer;
}

.searchStringIcon {
    width: 19px;
    height: 17px;
    display: inline-block;
    fill: #ffffff;
}

.tooltip {
    width: 300px;
}