.value {
    display: inline-block;
}

.value.outbound {
    color: #ff6a13;
    font-weight: bold;
}

.outboundMark {
    display: flex;
    align-items: center;
}

.mark {
    width: 9px;
    height: 9px;
    min-width: 9px;
    background: var(--primary-color);
    border-radius: 100%;
    display: inline-block;
    margin-right: 10px;
    margin-left: 2px;
}

.outbound.mark {
    background: #ff6a13;
}

.culture div:first-child {
    padding-bottom: 10px;
}

.cultureResistance {
    padding-bottom: 10px;
}

.secondCulture {
    padding-top: 10px;
}

.resistanceBlock {
    width: max-content;
}

.resistanceBlock .resistance {
    font-size: 11px;
    margin-bottom: 5px;
    border-bottom: 1px solid black;
}

.resistanceBlock .resistance {
    display: flex;
    justify-content: space-between;
}

.resistanceBlock .resistance .antibiotic {
    padding-right: 10px;
}
