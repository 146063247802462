.error {
    display: flex;
    align-items: center;
    padding: 15px;
    margin: 20px 0;
    font-weight: 700;
    color: #ff6a13;
    background-color: #fff0e7;
    border-bottom: 4px solid #ff6a13;
}

.error > div {
    padding-left: 20px;
}
