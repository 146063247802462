.container button {
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #ffffff !important;
    height: 32px;
    padding: 0 12px !important;
    margin-top: 10px;
}

.background {
    background: var(--primary-color) !important;
}

.chooseBiomaterial {
    background: #f04e5c !important;
    min-width: 221px !important;
}

.chooseBiomaterial:hover {
    background: #ec6b77;
    color: #ffffff;
}

.chosenBiomaterial > img {
    margin-left: 5px;
}

.chooseBiomaterial > img {
    display: none;
}

.chosenBiomaterial,
.chosenBiomaterial.inactive,
.chosenBiomaterial.inactive:disabled {
    background: var(--primary-color) !important;
    min-width: 225px;
    color: #ffffff;
}

.chosenBiomaterial:hover,
.chosenBiomaterial:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
}

.chosenBiomaterial.inactive:hover {
    cursor: default;
}

.chosenBiomaterial span {
    max-width: 177px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.changeBiomaterialIcon {
    vertical-align: middle;
}
