.layout {
    display: flex;
    flex-direction: column;
    background-color: var(--primary-bg-color);
    min-height: 100vh;
    color: var(--primary-text-color);
}

.main {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    max-width: 1200px;
    align-self: center;
    padding: 0 15px;
    width: 100%;
}
