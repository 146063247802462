.container {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 38px 38px 76px 38px;
}

.title {
    margin: 0 0 20px;
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}
