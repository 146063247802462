.container {
    background: #fff;
    width: 100%;
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 14px;
    line-height: 1.4;
}

.title {
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
    margin: 0 0 20px;
}

.container p {
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 12px;
    line-height: 1.4;
}

.settingRow {
    display: flex;
    margin: 20px 0;
    align-items: center;
}

.email {
    margin: 0 20px;
}

.addedUsersList {
    border-top: solid 1px #ccc;
    margin-top: 30px;
    padding-top: 10px;
}

.addedUsersList td:first-child {
    width: 70%;
}

.addedUsersList td {
    min-height: 32px;
    padding: 10px;
    font-size: 13px;
    line-height: 17px;
}

.addedUsersList tr:hover {
    background-color: #fff8d6 !important;
    cursor: pointer;
}

.addedUsersList tr:nth-child(even) {
    background: var(--table-row-bg-even);
    cursor: pointer;
}

.addedUsersList tr span {
    font-style: italic;
}

.createUserBlock {
    display: flex;
    flex-direction: column;
    border-top: solid 1px #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
}

.createUserBlock button {
    margin: 20px 0;
}
