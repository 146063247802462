.balloonHint {
    padding: 20px 20px;
    text-align: center;
}

.balloonBtn {
    width: auto;
    height: auto;
    max-height: 48px;
    margin: 0;
    padding: 12px 24px;
    background: 0 0;
    border: 2px solid var(--primary-color);
    cursor: pointer;
    color: var(--primary-color);
    font-size: 20px;
    font-weight: bold;
}

.balloonBtn:hover {
    color: #ffffff;
}

.officeTruncatedInfo {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ff6a13;
    margin-bottom: 30px;
    align-items: flex-start;
    text-align: left;
    max-width: 300px;
}
