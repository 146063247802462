.container {
    display: flex;
    flex-direction: column;
    padding: 0 38px;
    margin-top: 30px;
    text-align: center;
}

.container h1 {
    font-family: 'PT Sans Bold', sans-serif;
    margin: 0 0 20px;
}
