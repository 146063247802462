.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    z-index: 10;
    overflow-y: auto;
    justify-content: center;
}

.container {
    display: flex;
    width: 600px;
    padding: 42px;
    background: #fff;
    align-self: center;
    flex-direction: column;
    margin: 0 !important;
    overflow-x: auto;
    max-height: 100vh;
}

.head {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
}

.closeModalIcon:hover {
    cursor: pointer;
}

.title {
    font-family: 'PT Sans', sans-serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1.1px;
    line-height: 1;
    color: var(--primary-color);
    flex-grow: 1;
}

.overlay {
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1000;
}
