.container {
    background: #fff;
    width: 100%;
}

.content {
    padding: 40px 40px 80px 40px;
}

.title {
    margin: 0 0 20px;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}

.tableOrderHistoryTitle {
    width: 75%;
    min-width: 75%;
    max-width: 75%;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    color: var(--primary-color);
    vertical-align: bottom;
    letter-spacing: 0.3px;
}

.tableOrderHistory td,
.tableOrderHistory th {
    padding: 11px 10px;
}

.tableOrderHistory tr:nth-child(even) {
    background: var(--table-row-bg-even);
}

.tableOrderHistory,
.tableOrderHistory button {
    font-size: 14px;
}

.allOrders {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    color: var(--primary-color);
    align-items: baseline;
}
