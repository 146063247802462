html,
body {
    height: 100%;
    margin: 0;
}

:global(.mango-call-site) {
    position: fixed;
    z-index: 99999999;
    top: auto;
    bottom: 130px;
    right: 40px;
    left: auto;
}

:global(.mango-call-site) button {
    min-width: auto;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: var(--office-tag-bg-color) url('themes/icons/call.svg') no-repeat center center;
}

:global(.mango-call-site) button:hover {
    background-color: var(--primary-color);
}
