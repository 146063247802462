.container * {
    outline: none;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    background: var(--filter-results-bg-color);
    color: #ffffff;
    margin-bottom: 15px;
}

.filters input {
    width: 100%;
    height: 40px;
    border: 1px solid #dddddd;
    padding: 10px;
}

.searchType {
    height: 40px;
    border: 1px solid #dddddd;
    padding: 10px;
    margin: 0 10px;
}

.container .filters button {
    display: flex;
    align-items: center;
    min-width: auto;
    width: 120px;
    height: 40px;
}

.searchIcon {
    fill: #ffffff;
    margin-right: 10px;
    height: 20px;
    width: 20px;
}

.filters,
.statuses {
    display: flex;
    align-items: center;
}

.statuses {
    margin-top: 10px;
}

.statuses input[type='checkbox'] {
    border: none;
}

.statuses label {
    margin-right: 10px;
}

.filterPeriodDatePopup {
    top: 30px;
}
