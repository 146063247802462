.container {
    background: #fff;
    width: 100%;
}

.content {
    background: #fff;
    padding: 38px 38px 76px 38px;
    width: 100%;
}

.title {
    margin: 0 0 20px;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
}

.buttons {
    display: flex;
    font-size: 14px;
    margin-bottom: 30px;
}

.buttons button {
    font-size: 14px;
}

.buttons button {
    margin-right: 35px;
}

.tableOrders {
    width: 100%;
}

.tableOrdersTitles {
    text-align: left;
    font-family: 'PT Sans Bold', sans-serif;
    vertical-align: top;
    color: var(--primary-color);
    font-size: 16px;
    letter-spacing: 0.6px;
    height: 40px;
    line-height: 40px;
    font-weight: normal;
}

.tableOrdersTitles th,
.tableOrders tbody td {
    padding: 0 8px 0 8px;
    font-size: 16px;
}

.tableOrders tbody td {
    color: #000;
    height: 40px;
    font-size: 16px;
    vertical-align: middle;
}

.tableOrders tbody tr:hover {
    cursor: pointer;
}

.tableOrdersDate {
    width: 15%;
}

.tableOrdersOrder {
    width: 60%;
}

.tableOrdersStatus {
    width: 15%;
}

.tableOrdersAction {
    width: 10%;
}

.tableOrders tbody tr:nth-child(even) {
    background: var(--table-row-bg-even);
}

.status {
    font-weight: bold;
    color: var(--primary-color) !important;
}

.status span {
    font-weight: normal;
    opacity: 0.5;
    color: var(--primary-color) !important;
}

.modalSubTitle {
    margin-bottom: 9px;
    font-size: 16px;
    letter-spacing: 0.6px;
    text-align: left;
}

.closeRequestModal .modalSubTitle {
    font-weight: normal;
    font-family: 'PT Sans', sans-serif;
}

.courierDateTime {
    display: flex;
    margin-bottom: 26px;
    justify-content: space-around;
}

.courierDateTime input {
    width: 160px;
}

.courierDateTime :global(.react-datepicker__time-list-item--disabled) {
    display: none;
}

.courierDateTime select {
    font-size: 14px;
    padding: 2px 25px 2px 8px;
    box-shadow: inset 0 0 2px 0 var(--primary-color);
    border: solid 0.2px rgba(136, 139, 141, 0.42);
    border-radius: 3px;
    line-height: 1.4;
    outline: none;
}

.courierDate {
    margin-right: 45px;
}

.courierTime {
    margin-right: 15px;
}

.hasSpecialConditions {
    margin-left: 0;
    margin-right: 5px;
}

.specialConditionsBlock {
    display: flex;
    flex-direction: column;
}

.specialConditionsText {
    margin-top: 25px;
    width: 100%;
    height: 125px;
    padding: 4px;
    outline: none;
    border-radius: 3px;
    box-shadow: inset 0 0 5px 0 var(--primary-color);
    background-color: #fff;
    border: solid 0.2px var(--primary-color);
    resize: none;
}

.specialConditionsRemark {
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.4;
}

.actionButton {
    text-decoration: underline;
    font-size: 16px;
    letter-spacing: 0.6px;
    color: var(--primary-color);
}

.actionButton:hover {
    cursor: pointer;
}

.requestModal button,
.closeRequestModal button {
    margin-top: 25px;
    align-self: flex-start;
}

.requestModal .notice {
    margin-top: 15px;
    color: #f75376;
    font-size: 14px;
}

.allOrders {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    color: var(--primary-color);
}

.noticeStatus {
    margin-bottom: 40px;
}