.modalWrapper {
    height: auto !important;
}

.modal {
    padding: 0 !important;
    height: 90%;
    width: 90%;
    max-width: 1155px;
    max-height: none !important;
}

.modal > div {
    background: var(--primary-color);
}
.classCloseIcon {
    fill: #ffffff !important;
    color: #ffffff;
    stroke: #ffffff;
    outline: none;
    position: relative;
    top: 15px;
    right: 15px;
}

.officesNotFound > button {
    z-index: 1;
}

.officesContainer {
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    height: 100px;
    display: flex;
    align-items: center;
    padding: 25px 35px;
}

.emptyText {
    color: #dcd4d4;
    margin: 0 35px 0 35px;
    text-align: center;
    font-size: 18px;
}

.officesNotFound {
    background: var(--primary-color);
}

.title {
    width: 395px;
}

.title h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: #ffffff;
}

.searchBox {
    min-width: 432px;
}

.searchString {
    width: 100%;
    height: 50px;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    background: #fff;
    border-radius: 5px;
    border: none;
    color: rgba(19, 53, 63, 0.8);
}

.contentBlock {
    display: flex;
    flex: 1;
}

.officePanel {
    max-width: 430px;
    min-width: 430px;
    background-color: #fff;
}
