.container {
    background: #fff;
    padding: 38px 38px 76px 38px;
    width: 100%;
}

.chartResponsiveContainer :global(.recharts-legend-item-text) {
    color: #13353f !important;
}

.container :global([role='columnheader']:last-child) {
    text-align: center;
}

.chartResponsiveContainer tspan {
    font-size: 12px;
}

.chartResponsiveContainer {
    overflow-x: auto;
    margin-bottom: 20px;
}

.chartResponsiveContainer::-webkit-scrollbar {
    background-color: #fff;
    width: 7px;
}

.chartResponsiveContainer::-webkit-scrollbar:horizontal {
    background-color: #fff;
    height: 7px;
}

.chartResponsiveContainer::-webkit-scrollbar-track,
.chartResponsiveContainer::-webkit-scrollbar-track:hover {
    background-color: #fff;
}

.chartResponsiveContainer::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
}

.chartResponsiveContainer::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
}

.chartResponsiveContainer::-webkit-scrollbar-button {
    display: none;
}

.graphTooltip {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
    max-width: 300px;
    z-index: 5;
}

.graphTooltip span {
    display: block;
    padding: 5px 20px;
    font-weight: bold;
}

.graphTooltip .comment {
    font-weight: normal;
}

.date.outbound {
    background-color: #ff6a13;
}

.date {
    background-color: #0098b3;
    border-radius: 10px 10px 0 0;
    color: white;
}

.print {
    margin-bottom: 20px;
}

.printIcon {
    height: 16px;
    width: 16px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 2px;
}

.userComment {
    max-width: 300px;
    text-align: center;
}
