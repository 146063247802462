.container {
    background: #fff;
    width: 100%;
    font-family: 'PT Sans Regular', sans-serif;
    font-size: 14px;
    line-height: 1.4;
}

.title,
.subTitle {
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
    margin: 0 0 20px;
    color: #000000;
}

.subTitle {
    font-size: 18px;
}

.backLink {
    color: var(--primary-color);
    padding: 0 0 15px 0;
    margin-bottom: 26px !important;
    border-bottom: solid 1px rgba(204, 204, 204, 0.35);
}

.userName {
    font-size: 27px;
    font-weight: bold;
}

.fullName {
    margin: 10px 0 10px 0;
}

.actions {
    display: flex;
    align-items: flex-end;
}

.actions {
    display: flex;
    align-items: flex-end;
}

.actions button {
    margin: 20px 20px 0 0;
}

.permissions {
    display: flex;
    flex-direction: column;
}

.permissions input {
    margin-left: 0;
}

.settingRow {
    display: flex;
    margin: 10px 0;
    flex-direction: column;
    max-width: 400px;
}

.actionModal {
    margin-bottom: 30px;
}

.officeList {
    height: 500px;
    min-width: 250px;
    margin-top: 10px;
    overflow-x: auto;
    border: 1px solid #ababab;
    padding: 5px;
    border-radius: 5px;
}

.selectedOfficeList {
    margin-right: 20px;
}

.officeList div:hover,
.officeList input:hover {
    cursor: pointer;
    background: #d7e4ef !important;
}

.officeList div {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 16px;
}

.offices {
    display: flex;
    margin-bottom: 30px;
}

.error {
    border: 2px solid red;
}

.selectedOfficeList {
    width: 100%;
}
