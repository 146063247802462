.container {
    width: 100%;
}

.title {
    margin: 70px 0 25px 67px;
    font-size: 36px;
    color: var(--h-entry-color);
}

.content {
    display: flex;
    background-color: #fff;
    flex-direction: column;
}

.top {
    display: flex;
    height: 65px;
    border-bottom: 1px solid #ddd;
}

.linkBack {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 35px;
    height: 35px;
    background: #fff;
    box-shadow: 0 0 8px rgb(0 0 0 / 20%);
    border-radius: 50%;
    cursor: pointer;
    transition: all ease-in-out 350ms;
    margin-left: 13px;
}

.linkBack:hover {
    box-shadow: 0 0 18px rgb(0 0 0 / 20%);
}

.tabs {
    margin-left: 20px;
}

.tab {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 30px;
    font-size: 16px;
    cursor: pointer;
    border-bottom: 3px solid var(--primary-color);
    color: var(--primary-color);
}

.form {
    margin: 50px 70px;
    width: 300px;
    align-self: var(--form-justify-content);
}

.row {
    margin-bottom: 20px;
    position: relative;
}

.form button {
    padding: 9px 24px;
    border: none;
    display: inline-block;
    background: var(--primary-color);
    color: #fff;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    transition: all ease-in-out 350ms;
    width: 100%;
}

.form button:hover {
    background: var(--primary-color);
}

.notice {
    font-size: 16px;
}

.recaptcha {
    margin-bottom: 20px;
}
