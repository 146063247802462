.headerMenu {
    display: flex;
    align-self: center;
    max-width: 1200px;
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
    padding: 0 15px;
}

.menu {
    display: flex;
    align-items: flex-start;
    width: 100%;
    border-bottom: 3px solid var(--primary-color);
}

.menuItem {
    display: flex;
    background: #ffffff;
    align-items: center;
    flex-grow: 1;
}

.menuItem:not(:last-child) {
    margin-right: 14px;
}

.headerMenuIcon,
.headerMenuLaboratory,
.headerMenuPreorders {
    width: 27px;
    height: 28px;
    margin-right: 10px;
}

.headerMenuLaboratory {
    stroke: #ffffff;
}

.menuItem a {
    display: flex;
    align-items: center;
    position: relative;
    padding: 7px 13px;
    cursor: pointer;
    background: #fff;
    font-size: 16px;
    text-decoration: none !important;
    width: 100%;
}

.menuItem a:not(.activeMenu) {
    color: var(--primary-color) !important;
}

.activeMenu {
    background: var(--primary-color) !important;
    color: #fff !important;
}

.activeMenu .headerMenuPreorders {
    stroke: #ffffff;
}

.menuItem a img:not(:first-child) {
    display: none;
}

.activeMenu img:last-child {
    display: inline-block !important;
}

.messageCounter {
    font-family: 'PT Sans Bold', sans-serif;
    position: relative;
    top: -13px;
    right: 0;
    min-width: 25px;
    border-radius: 50%;
    background: var(--message-counter-color);
    color: #fff;
    line-height: 17px;
    letter-spacing: 0.7px;
    font-size: 10px;
    text-align: center;
    padding: 3px;
}
