.button {
    font-family: 'PT Sans Bold', sans-serif;
    color: var(--primary-color) !important;
    background: none !important;
    border: none !important;
    border-radius: unset;
    padding: 0 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71429;
    white-space: nowrap;
    transition: all 0.3s;
    height: auto;
}

.button:hover {
    color: #ff6a13;
    background: transparent;
}

.button:disabled {
    color: #afafaf;
    background: none;
}
