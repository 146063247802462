.container {
    display: flex;
    background: transparent;
}

.photo {
    align-self: center;
    margin-right: 20px;
}

.content {
    display: flex;
    flex-direction: column;
}

.name {
    font-size: 18px;
    font-family: 'PT Sans Bold', sans-serif;
    color: var(--primary-color);
    font-weight: bold;
}

.closePopupIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all ease-in-out 350ms;
    transform: rotate(0);
}

.closePopupIcon:hover {
    transform: rotate(90deg);
}

.popupCompanyContacts {
    max-height: 700px;
    overflow-x: auto;
}

.popupCompanyInfo h6 {
    margin-bottom: 5px;
    font-size: 12px;
    font-family: 'PT Sans Bold', sans-serif;
    text-transform: uppercase;
    color: var(--secondary-color) !important;
    letter-spacing: 0.4px;
    font-weight: bold;
}

.popupCompanyInfo {
    font-size: 14px;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 2;
    min-width: 353px;
    max-width: 600px;
    margin-top: 5px;
    padding: 26px 35px;
    background: #fff;
    opacity: 1;
    box-shadow: 0 1px 5px 0 var(--box-shadow-color);
    animation: fade ease-in-out 350ms alternate;
}

.profileLink,
.companyInfoTitle {
    font-size: 14px;
    display: inline-block;
    color: var(--secondary-color) !important;
    font-family: 'PT Sans Bold', sans-serif;
    text-decoration: none !important;
    font-weight: bold;
}

.companyInfoTitle:hover {
    text-decoration: underline !important;
    cursor: pointer;
}

.popupCompanyContacts div {
    max-height: 500px;
    overflow-y: auto;
    margin-top: 5px;
}

.popupCompanyContacts div:not(:last-child) {
    margin-bottom: 7px;
}

.mailIcon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: 2px;
}

.popupCompanyEmail {
    color: #75787a;
}

.popupCompanyContactLink {
    color: var(--primary-text-color);
}

.selectedOfficeIcon {
    width: 12px;
    height: 10px;
    position: relative;
    right: 20px;
    display: inline-block;
    transition: all ease-in-out 350ms;
    transform: rotate(-90deg) !important;
}

.selectedOfficeIcon:hover {
    cursor: pointer;
}

.selectedOfficeIconRotate {
    transform: rotate(-270deg) !important;
}

.group {
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin-top: 5px;
    flex-direction: column;
    position: relative;
}

.selectedOfficeBlock {
    display: flex;
    align-items: center;
}

.officeListLinkWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.officeListLink {
    padding: 10px 5px 10px 12px;
    text-decoration: none;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    color: var(--primary-color);
}

.officeListLink:hover {
    text-decoration: none;
    background: #fff8d6;
    cursor: pointer;
}

.officeListMenu {
    position: absolute;
    z-index: 2;
    width: 226px;
    border: 1px solid var(--box-shadow-color);
    border-top: none;
    background: #fff;
    box-shadow: 0 1px 7px 0 var(--box-shadow-color);
    transition: all ease-in-out 350ms;
    max-height: 500px;
    overflow-x: auto;
    padding: 10px;
}

.officeListItemGroups {
    margin-top: 10px;
}

.menuShow {
    opacity: 1 !important;
    display: inline-block;
    z-index: 999;
}

.menuHidden {
    opacity: 0 !important;
    visibility: hidden;
}

.profileLabel,
.logout {
    display: inline-block;
    user-select: none;
    width: 226px;
    padding: 9px 12px;
    background: #fff;
    font-size: 16px;
    color: var(--primary-color);
    cursor: pointer;
    box-shadow: 0 1px 7px 0 var(--box-shadow-color);
    transition: all ease-in-out 350ms;
    margin-bottom: 3px;
    max-height: 85px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.logout {
    display: flex;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    align-self: center;
}

.logoutIcon {
    width: 11px;
    height: 13px;
    display: inline-block;
}

.searchOfficesIcon {
    margin-left: 10px;
    margin-right: 10px;
    width: 19px;
    height: 19px;
    display: inline-block;
}

.searchOffices {
    display: flex;
    align-items: center;
    border: 1px solid var(--box-shadow-color);
    background: #fff;
    box-shadow: 0 1px 7px 0 var(--box-shadow-color);
    padding: 10px 0 10px 0;
    width: 226px;
}

.searchOffices input {
    border: none;
}

.searchOffices input::placeholder {
    color: var(--primary-color);
}

.linkShow {
    color: var(--primary-color);
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 1px dashed var(--primary-color);
}

.selectMenu.selectedOfficeIcon {
    transform: rotate(-90deg) !important;
}

.selectMenu.selectedOfficeIconRotate {
    transform: rotate(0deg) !important;
}

.officeClaps {
    fill: none;
    stroke: #2c3e50;
    margin-left: 5px;
    min-width: 20px;
    min-height: 20px;
}

.officeClaps:hover {
    fill: #f79440 !important;
    cursor: pointer;
}

.officeClapsActive {
    fill: #f79440;
    stroke: #f79440;
    transform: rotate(45deg);
}

.officeClapsActive:hover {
    fill: none !important;
}

.officeListOverlay {
    opacity: 0.3;
}
