.product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product .productLeft {
    flex-basis: 0;
    flex-grow: 2;
}

.product .productRight {
    display: flex;
    min-width: 50px;
    justify-content: flex-end;
    align-items: baseline;
    flex-basis: 0;
    flex-grow: 1;
}

.summaryListInfo {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 4px;
}

.quantityProductList {
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #13353f;
    opacity: 0.5;
}

.productName {
    font-family: 'Open Sans', 'PT Sans', sans-serif;
    font-size: 18px;
    line-height: 1.5;
    color: #13353f !important;
    font-weight: 600;
    white-space: normal;
    text-align: left;
}

button.productName:hover {
    opacity: 0.4;
    color: #ff6a13 !important;
}

.productBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productTags {
    margin-top: 4px;
    font-size: 14px;
    color: rgba(19, 53, 63, 0.5);
    font-family: 'Open Sans', 'PT Sans', sans-serif;
}

.productTags span {
    margin-right: 20px;
}

.productRestore {
    color: #b6c1c2;
    border: 2px solid #dde4e5;
}

.productRestore:hover {
    color: #b6c1c2;
    background-color: transparent;
    border: 2px solid #dde4e5;
    opacity: 0.5;
}

.productDelete {
    line-height: 0.8;
    color: #13353f;
    font-style: normal;
    font-weight: 400;
    opacity: 0.5;
    font-size: 28px;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.27, 0.67, 0.41, 1.06);
    padding-top: 5px;
    min-width: auto;
    margin-left: 15px;
}

.productDelete:hover {
    opacity: 1;
}

.productDelete svg {
    opacity: 0.5;
    stroke: #13353f;
    fill: #13353f;
}

.titleContainer {
    display: flex;
    align-items: center;
}
