.button {
    font-family: 'PT Sans', sans-serif;
    background-color: var(--primary-color);
    padding: 8px 24px;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    line-height: 24px;
    color: #fff;
}

.button:hover {
    background-color: var(--secondary-color);
}

.button:disabled {
    background-color: #afafaf;
    border-color: #afafaf;
    cursor: default;
}
