.container:not(:first-child) {
    margin-top: 15px;
}

.notice {
    width: 100%;
    padding: 10px;
    position: relative;
    background: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    text-align: center;
    margin: 0 auto;
}

.notice:after,
.notice:before {
    content: '';
    border: solid transparent;
    position: absolute;
    top: 100%;
    left: 50%;
}

.notice:after {
    border-top-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}

.notice:before {
    border-top-color: var(--primary-color);
    border-width: 9px;
    margin-left: -9px;
}

.error {
    border-color: red;
    color: red;
}

.error.notice:before {
    border-top-color: red;
}
