.container {
}

.container select {
    font-family: 'PT Sans Regular', sans-serif;
    width: 100%;
    height: 40px;
    border: 2px solid rgba(204, 204, 204, 0.23);
    font-size: 14px;
    line-height: 1.4;
    outline: none;
    padding: 0 5px;
    background: none;
    color: var(--primary-color);
}

.container select option {
    color: var(--primary-color);
}
