.container {
    padding: 10px;
    color: #333;
    background: #fafafa;
    border: 1px solid #ccc;
    font-size: 16px;
    border-radius: 5px;
    text-align: center;
    margin: 0 !important;
}
.info {
    color: #204a8e;
    background: #c9ddff;
    border: 1px solid #4c699b;
}
.success {
    color: #2b7515;
    background: #ecffd6;
    border: 1px solid #617c42;
}
.warn {
    color: #756e15;
    background: #fffbd1;
    border: 1px solid #87803e;
}
.error {
    color: #ba3939;
    background: #ffe0e0;
    border: 1px solid #a33a3a;
}
