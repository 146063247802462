.materialsToolbarWrapper {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: var(--order-panel-bg-color);
}

.materialsToolbar {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

.materialsToolbarItem:first-child {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.materialsToolbarItem:last-child {
    display: flex;
    align-items: center;
}

.materialsToolbar input {
    width: 463px;
    height: 40px;
    background: #fff;
    border: none;
    padding: 0 33px 0 17px;
    font-size: 18px;
}

.materialsToolbar input::placeholder {
    color: var(--primary-color);
}

.error {
    border: 2px solid #f75376 !important;
}

.clearInputIcon {
    width: 11px;
    height: 10px;
    position: relative;
    right: 25px;
    stroke-width: 3px;
}

.clearInputIcon:hover {
    cursor: pointer;
}

.materialsToolbarTotal {
    width: 100%;
    margin-right: 20px;
    margin-left: 20px;
    color: #fff;
    letter-spacing: 0.4px;
    font-size: 18px;
    font-family: 'PT Sans Bold', sans-serif;
    text-align: center;
}

.materialsToolbarButtons {
    height: 40px;
    color: #fff;
    letter-spacing: 0.6px;
    font-size: 16px;
    transition: all ease-in-out 150ms;
    display: flex;
}

.materialsToolbarButtons {
    height: 40px;
    color: #fff;
    letter-spacing: 0.6px;
    font-size: 16px;
    transition: all ease-in-out 150ms;
    display: flex;
}

.materialsToolbarButtons button {
    min-width: 150px;
    width: 100%;
    height: 40px;
}

.materialsToolbarButtons button:hover {
    background: var(--order-button-hover-bg-color) !important;
}

.materialsToolbarButtons button:first-child {
    border: 2px solid #fff;
    color: #fff;
    background: transparent;
    margin-right: 20px;
}

.materialsToolbarButtons button:last-child {
    background: var(--order-button-bg-color);
}
