.container {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
}

.wrapper {
    display: flex;
    margin-bottom: 16px;
}

.leftArrow {
    align-self: flex-start;
    margin-top: 20px;
    margin-left: 5px;
}

.list {
    padding: 14px;
    min-height: 90px;
    background: #f2f9fa;
    margin-left: 15px;
    width: 100%;
}

.productNumbers {
    font-family: Open Sans, sans-serif;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    font-weight: 600;
    color: #13353f;
    opacity: 0.5;
}

.productNumbers .productArticle {
    font-size: 14px;
    line-height: 22px;
    margin-right: 15px;
}

.hidden {
    display: none;
}

.product {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #dce1e2;
    padding: 10px 0;
}

.product:last-child {
    border: none;
}

.productLeftBlock {
    display: flex;
    justify-content: space-between;
}

.productName {
    font-family: Open Sans, sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #13353f;
    margin-right: 15px;
}

.productName:hover {
    cursor: pointer;
    opacity: 0.4;
    color: var(--primary-color);
}

.productArticle {
    font-family: Open Sans, sans-serif;
    font-size: 11px;
    line-height: 16px;
    color: #13353f;
    opacity: 0.6;
    margin-top: 5px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hiddenListIcon:hover,
.showListIcon:hover {
    cursor: pointer;
}
