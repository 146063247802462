.container {
    background: #fff;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 38px 38px 76px 38px;
    width: 100%;
}

.title {
    margin: 0 0 20px;
    font-family: 'PT Sans Bold', sans-serif;
    font-size: 30px;
    letter-spacing: 0.6px;
    font-weight: bold;
    text-align: center;
}

.date {
    margin: 0 0 20px 2px;
    color: #999 !important;
    font-style: italic;
    font-size: 11px;
    line-height: 16px;
    white-space: nowrap;
}

.newsBack {
    margin-top: 30px;
    border-top: 1px solid var(--box-shadow-color);
    padding-top: 30px;
    text-decoration: underline !important;
}

.newsBack a {
    color: var(--primary-color) !important;
}

.fileIcon {
    height: 92px;
    width: 66px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: block;
}

.files {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    word-break: break-word;
}

.file {
    width: 130px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 10px;
}

.file a {
    color: var(--primary-color) !important;
    display: flex;
    margin-top: 10px;
    align-items: center;
    flex-direction: column;
    text-decoration: underline !important;
}

.fileExtension {
    color: #999;
    text-decoration: none;
}

.text {
    display: block;
}

.text table {
    width: 100% !important;
    margin-left: 0 !important;
}

.text a:visited {
    color: inherit;
}
