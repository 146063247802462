.container {
    background: #fff;
    width: 100%;
    display: flex;
    margin-top: 20px;
}

.top {
    position: sticky;
    top: 0;
    font-size: 20px;
    left: 50%;
    z-index: 2;
    display: inline-block;
    transform: translateX(-50%);
    vertical-align: center;
}

.top svg {
    fill: none !important;
    margin-right: 10px;
    position: relative;
    top: 5px;
}

.topInner {
    display: flex;
    align-items: center;
}

.counter {
    display: inline-block;
    color: var(--primary-color);
    padding: 10px;
    font-weight: bold;
    border: 1px solid var(--secondary-color);
    border-radius: 10px 0 0 10px;
    height: 50px;
    background: #ffffff;
}

.continue {
    border: 1px solid var(--secondary-color);
    border-left: none;
    border-radius: 0 10px 10px 0;
    padding: 10px;
    height: 50px;
    color: var(--primary-color);
    background: #ffffff;
}

.continue:hover {
    background: var(--primary-color);
    color: #ffffff;
    cursor: pointer;
}

.item {
    margin-bottom: 20px;
}

.productList {
    width: 100%;
    padding: 0 0 60px 20px;
    margin-bottom: 70px;
}

.searchString {
    height: 50px;
    width: 100%;
    border: 1px solid #b6b6b6;
    padding: 10px;
    margin-bottom: 15px;
}

.list {
    padding: 24px 24px 132px 24px;
    border: 1px solid #e8eced;
    height: 100%;
}

.list li {
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 16px;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e8eced;
}

.list li:last-child {
    border-bottom: none;
}

.name {
    font-size: 16px;
    font-weight: bold;
}

.name:hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.67);
}

.fullName {
    font-weight: normal;
    font-size: 14px;
    margin-top: 5px;
}

.article {
    font-size: 14px;
    color: rgba(19, 53, 63, 0.5);
    margin: 10px 0;
}

.list button {
    width: 100%;
}

.activeButton {
    background: var(--order-button-bg-color);
}

.activeButton:hover {
    background: var(--order-button-hover-bg-color) !important;
}

.sideBarBlock {
    width: 350px;
    border: 1px solid #e8eced;
}

.items {
    border-bottom: 1px solid #e8eced;
}

.link {
    position: relative;
    display: block;
    font-weight: 700;
    color: #0097b3;
    word-break: break-word;
    word-wrap: break-word;
    transition: color 0.2s, background-color 0.2s;
    padding: 24px 50px 24px 24px;
}

.link:hover {
    cursor: pointer;
}

.open {
    background: #cceaf0;
}

.open:before {
    transform: rotate(-180deg);
}

.linkIcon {
    position: relative;
    padding-left: 56px;
}

.link,
.title {
    font-size: 18px;
    line-height: 1.33333;
}

.second {
    display: none;
    position: relative;
    padding: 0;
    color: #0289a2;
    background-color: #f8fcfc;
    z-index: 1;
}

.second .secondLink {
    padding-left: 58px;
}
.secondLink {
    position: relative;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #0289a2;
    transition: color 0.3s, background 0.3s;
    padding: 12px 50px 14px 24px;
}

.secondLink:hover {
    color: #fff;
    background-color: #0289a2;
    cursor: pointer;
}

.secondLink.linkThird:after {
    content: '•';
    position: absolute;
    left: auto;
    top: calc(50% - 2px);
    right: 26px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #1797b1;
    border-bottom: 0;
    transition: transform 0.5s, border-top-color 0.5s, -webkit-transform 0.5s;
}

.third {
    position: relative;
    padding: 0;
    color: #0289a2;
    background-color: #f2f6f7;
    z-index: 1;
}

.thirdList {
    color: #fff;
    padding-left: 0;
}

.second .thirdList .thirdLink {
    padding-left: 80px;
}

.thirdList .thirdLink {
    position: relative;
    display: block;
    padding: 12px 20px 14px 46px;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #0289a2;
    word-break: break-word;
    transition: color 0.3s, background 0.3s;
}

.second .thirdList .thirdLink:before {
    left: 59px;
}

.thirdList .thirdLink:before {
    content: '•';
    position: absolute;
    display: block;
    left: 25px;
    top: 12px;
}

.thirdList .thirdLink:hover {
    background-color: #cceaf0;
    cursor: pointer;
}

.secondList {
    color: #fff;
    padding-left: 0;
}

.link.linkSecond:before {
    content: '•';
    position: absolute;
    left: auto;
    top: calc(50% + 10px);
    right: 24px;
    width: 0;
    height: 0;
    border-bottom: 0;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.selectedCategory .thirdLink {
    color: #ff6a13;
}
