.container {
    padding: 40px 68px;
    background-color: var(--secondary-color);
    color: #fff;
}

.title {
    margin: 0 0 30px;
    font-size: 28px;
    color: #ffffff;
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
}

.itemTitle {
    margin-bottom: 20px;
    border-bottom: 1px dotted #fff;
    cursor: pointer;
    font-size: 16px;
    white-space: nowrap;
    align-self: flex-start;
    color: #ffffff;
}

.description {
    height: 0;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 19px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all ease-in-out 350ms;
    will-change: transform;
}

.description p {
    margin-bottom: 15px;
}

.description a {
    color: #ffffff;
}

.activeItem + .description {
    opacity: 1;
    visibility: visible;
    height: 100%;
}
