.slideshow {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    flex-grow: 1;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    width: 100%;
    border-radius: 40px;
}

.banner {
    height: 120px;
    width: 100%;
    max-width: 750px;
}
